//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Override primary color variants
// Primary colors
$primary:       									#168aad !default; // Bootstrap variable
$primary-hover:    									#184e77 !default; // Custom variable
$primary-light:    									#caf0f8 !default; // Custom variable
$primary-inverse:  									#ffffff !default;  // Custom variable

// Success colors
$success:       									#95d5b2 !default; // Bootstrap variable
$success-hover:    									#2d6a4f !default; // Custom variable
$success-light:    									#C9F7F5 !default; // Custom variable
$success-inverse:  									#ffffff !default; // Custom variable

// Inco colors
$info:         		 								#8950FC !default; // Bootstrap variable
$info-hover:   		 								#7337EE !default; // Custom variable
$info-light:   		 								#EEE5FF !default; // Custom variable
$info-inverse: 		 								#ffffff !default; // Custom variable

// Warning colors
$warning:       									#ffc300 !default; // Bootstrap variable
$warning-hover:    									#ff8800 !default; // Custom variable
$warning-light:    									#FFF4DE !default; // Custom variable
$warning-inverse:  									#ffffff !default; // Custom variable

// Danger colors
$danger:        									#ca031a !default; // Bootstrap variable
$danger-hover:     									#a63726 !default; // Custom variable
$danger-light:     									#FFE2E5 !default; // Custom variable
$danger-inverse:   									#ffffff !default; // Custom variable






