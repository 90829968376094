@import url(https://fonts.googleapis.com/css?family=Raleway:400, 200, 300, 800);
@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);

/* GENERAL CUSTOM STYLE */
.bg-burgundy {
  background-color: #783233 !important;
}
.bg-gold {
  background-color: #d6a878 !important;
}
.bg-offwhite {
  background-color: #f1f1f1 !important;
}
.bg-green {
  background-color: #28a745 !important;
}
.bg-orange {
  background-color: #ffc107 !important;
}
.bg-red {
  background-color: #dc3545 !important;
}
.bg-blue {
  background-color: #17a2b8 !important;
}
.bg-gray {
  background-color: #caf0f8 !important;
}
.col-burgundy {
  color: #783233 !important;
}
.col-gold {
  color: #d6a878 !important;
}
.col-offwhite {
  color: #f1f1f1 !important;
}

/* ASIDE CUSTOM STYLE */
.aside {
  background-color: #264653;
  border-right: 1px solid #264653;
}
.aside .brand {
  background-color: #264653 !important;
}
.aside .aside-menu {
  background-color: transparent;
}
.aside .menu-item a:hover,
.aside .active {
  background-color: #f4a261 !important;
}
.aside .menu-item .menu-icon-custom svg {
  height: 23px !important ;
  width: 23px;
  margin-left: -2px;
  color: red;
}
.aside .menu-item-open .menu-toggle {
  background: #e9c46a !important;
}
.aside .active .menu-text {
  color: #264653 !important;
}
.aside-menu
  .menu-nav
  > .menu-item.menu-item-active
  > .menu-link
  .menu-icon.svg-icon
  svg
  g
  [fill] {
  transition: fill 0.3s ease;
  fill: #264653;
}

/* .aside-menu .menu-nav > .menu-item > .menu-heading .menu-icon::before, .aside-menu .menu-nav > .menu-item > .menu-link .menu-icon::before {
    color:#2A9D8F;
    transition: color 0.5s ease;
  }
  .aside-menu .menu-nav a:hover .menu-icon::before, .aside-menu .menu-nav a:hover .menu-icon::before {
    background-color:#90E0EF;
    transition: color 0.5s ease;
  } */
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-icon,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-icon {
  color: #264653;
  transition: color 0.5s ease;
}

.aside-num-custom {
  background-color: #dd872a;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-top: 2px !important;
  border-radius: 4px;
  text-align: center;
  color: #fff;
}
.aside-num-custom-inli {
  background-color: #dd872a;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-top: 10px !important;
  border-radius: 4px;
  text-align: center;
  color: #fff;
}
.header {
  background-color: #264653;
}

.PannelStep {
  background: rgba(0, 0, 0, 0.02);
  padding: 3%;
  border-radius: 4px;
  color: #292929;
  font-size: 15px;
}
.PannelStep .step {
  opacity: 0.5;
}
.PannelStep .stepNumber {
  background: #783233;
  color: #264653;
  padding: 0 7px 0 7px;
  border-radius: 5px;
}
.PannelStep .active {
  opacity: 1;
}

/* MENU/TOPBAR CUSTOM STYLE */
.brand-dark .header-mobile {
  background-color: #f1f1f1 !important;
}
.burger-icon span,
.burger-icon span:after,
.burger-icon span:before {
  background-color: #0096c7 !important;
}

.topbar-icon-custom i {
  font-size: 25px !important;
  opacity: 0.8 !important;
}
.topbar-icon-custom:hover i {
  opacity: 1 !important;
}
.watchlist {
  color: #c24e3f !important;
}

.btn-custom-wallet {
  margin: 5px !important;
  padding: 0 !important;
}
.wallet-cont {
  background-color: rgba(0, 0, 0, 0.06) !important;
  padding: 10px !important;
  padding-left: 20px !important;
  border-radius: 4px !important;
  cursor: pointer;
}
.btn-custom-wallet .wallet-num {
  margin-bottom: 5px;
  margin-right: 10px;
  color: #292929 !important;
}
.btn-custom-wallet .topbar-icon-custom {
  padding-top: 10px;
}
.wallet {
  color: #915627 !important;
}

.cont_percentage_won {
  padding: 3%;
}
.cont_percentage_won .market_price {
  background: rgba(0, 0, 0, 0.09);
  margin: 4%;
  border-radius: 4px;
  padding: 5px;
}
.cont_percentage_won .offer_price {
  background: rgba(0, 0, 0, 0.09);
  margin: 4%;
  border-radius: 4px;
  padding: 5px;
}
.cont_percentage_won .percentage_price {
  border-radius: 4px;
  padding: 5px;
  font-size: 20px;
}

/* TABLE CUSTOM STYLE */
.TableCustom input[type="checkbox"] {
  position: relative;
  cursor: pointer;
  vertical-align: middle !important;
}
.TableCustom input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: 2px solid #0096c7;
  border-radius: 3px;
  background-color: white;
}
.TableCustom input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid #0096c7;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}
.TableCustom input[type="checkbox"]:disabled:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: 2px solid #0096c7;
  border-radius: 3px;
  background-color: white;
  opacity: 0.3;
}

.TableCustom .card-custom-filter {
  width: 100%;
  margin: 2% 0;
  border-radius: 4px;
}

.TableCustom {
  word-break: break-word !important;
  background: #fff !important;
  color: #3f4254 !important;
  border-radius: 10px !important;
  padding: 0;
  margin: 0 !important;
}

.TableCustom .rt-table,
.TableCustom .-striped {
  background: #fff !important;
  border: 0 !important;
  border-radius: 10px !important;
}
.TableCustom .rt-td {
  white-space: unset !important;
}
.TableCustom .-headerGroups {
  display: none;
  background-color: #fff !important;
  border: 0 !important;
  box-shadow: 0px 0px !important;
}
.TableCustom .-filters {
  background-color: #fff !important;
}

.TableCustom .rt-resizable-header {
  padding: 20px 0 10px 0 !important;
  background: #fff !important;
  text-transform: uppercase !important;
}
.TableCustom .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgb(120, 50, 51, 1);
}
.TableCustom .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgb(120, 50, 51, 1);
}

.TableCustom .rt-td {
  padding: 20px 1% 20px 1%;
}
.TableCustom .rt-td,
.TableCustom .rt-tr-group {
  background: #fff !important;
  border-right: 0 !important;
  border-left: 0 !important;
}
.TableCustom .-btn {
  background: #0096c7 !important;
  border-color: #0096c7 !important;
  color: #fff !important;
}

.TableCustom input,
.TableCustom select {
  background-color: #f3f6f9 !important;
  border-color: #f3f6f9 !important;
}

.TableCustom .-pagination {
  border: 0 !important;
  padding: 10px 1% 10px 1%;
}

.TableCustom .custom-button {
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.TableCustom .cont-hide {
  opacity: 0;
  width: 0;
  height: 0;
  font-size: 0;
  transition: width 0.2s 0.2s, height 0.2s 0.2s, opacity 0.2s;
}
.TableCustom .cont-show {
  text-align: center;
  opacity: 1;
  width: 98%;
  height: auto;
  font-size: auto;
  background: rgba(0, 0, 0, 0.05);
  padding: 10px 1% 10px 1%;
  margin-bottom: 10px;
  transition: width 0.2s, height 0.2s, opacity 0.2s 0.2s;
}

.TableCustom .cont-hide-select {
  opacity: 0;
  width: 0;
  height: 0;
  font-size: 0;
  transition: width 0.2s 0.2s, height 0.2s 0.2s, opacity 0.2s;
}
.TableCustom .cont-show-select {
  opacity: 1;
  width: 98%;
  height: auto;
  font-size: auto;
  margin-bottom: 10px;
}

.TableCustom .shortDescrption {
  opacity: 1;
  height: auto;
  font-size: auto;
  background: rgba(0, 0, 0, 0.02);
  padding: 10px 1% 10px 1%;
  margin-bottom: 10px;
  transition: width 0.2s, height 0.2s, opacity 0.2s 0.2s;
}
.TableCustom .shortDescrptionInt {
  opacity: 1;
  width: 98%;
  margin: auto;
  height: auto;
  font-size: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 2%;
  margin-bottom: 10px;
  transition: width 0.2s, height 0.2s, opacity 0.2s 0.2s;
}

.TableCustom .click-span-cont-hide:hover {
  cursor: pointer;
}

.TableCustom .imgCustom {
  width: 200px;
  text-align: center;
  margin: auto;
}
.TableCustom .cont-imgCustom,
.TableCustom .cont-countryCustom,
.TableCustom .cont-statusCustom {
  text-align: center;
}
.TableCustom .cont-nameCustom {
  width: 100%;
}
.TableCustom .titleAuction {
  font-size: 17px;
}
.imgCustomAuctionName {
  width: 50px;
  height: 50px;
  border: 1px solid #ddd;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 5px;
}

.TableCustom .cont-statusCustom span {
  font-size: 13px;
  padding: 15px !important;
}

.TableCustom .cont-countryCustom .label-dot {
  padding: 5px !important;
}
.TableCustom .cont-countryCustom span {
  font-size: 17px;
}

.TableCustom .actionsSelect {
  font-size: 12px;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  text-align: center;
}

.TableCustom .countDownCustom {
  background: rgba(0, 0, 0, 0);
  margin: 10px 0;
  border-radius: 10px;
  padding: 0px 0;
}
.TableCustom .countDownCustom span {
  font-size: 20px !important;
}
.TableCustom .contContdown {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.TableCustom .contContdown li {
  position: relative;
  float: left;
  margin-top: 15px;
  width: 25%;
}
.TableCustom .contContdown .overDivHour:after {
  content: ":";
  font-size: 20px;
  position: absolute;
  top: 0;
  right: -3.5px;
}
.TableCustom .countDownCustom .Clock-hours .contInputCountdown {
  background: rgba(0, 0, 0, 0.05);
  font-size: 20px !important;
  margin: auto !important;
  padding: 0 5px !important;
  border-radius: 4px;
}
.TableCustom .countDownCustom .Clock-legend .contInputCountdown {
  background: rgba(0, 0, 0, 0);
  font-size: 10px !important;
  margin: auto !important;
  padding: 0 5px !important;
  border-radius: 4px;
}
.TableCustom .countDownCustom .Clock-data {
  margin-top: 5px;
  font-size: 13px !important;
}

.TableCustom .warehouseStatus {
  font-size: 15px !important;
  padding: 17px !important;
}

.auction-icon-custom i {
  margin-left: 5px;
  opacity: 0.8 !important;
  cursor: pointer;
}
.auction-icon-custom:hover i {
  opacity: 1 !important;
}

.ContMyCellar {
  background: #fff;
  border-radius: 10px 10px 0 0 !important;
}
.ContMyCellar .MenuMyCellar {
  padding: 10px;
  margin-top: 10px;
}
.ContMyCellar .MenuMyCellar li {
  cursor: pointer;
}
.ContMyCellar .MenuMyCellar li.active {
  border-bottom: 2px solid #0096c7;
  color: #0096c7 !important;
}
.ContMyCellar .MenuMyCellar li.active .nav-link {
  color: #0096c7 !important;
}

.ContMyCellar .TableCustom {
  border-radius: 0 0 10px 10px !important;
}

.subComponent {
  background-color: rgba(214, 168, 120, 0.2);
  padding: 2%;
}
.subComponent h6 {
  color: #783233;
  padding-bottom: 10px;
}
.subComponent ul {
  list-style-type: none;
}
.subComponent li {
  border-radius: 4px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.03);
}
.subComponent .imgCustom {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;
}

.dropdown-menu-anim .quality-score-cont-princ {
  margin-top: 10px;
}
.quality-score-cont {
  margin-left: 5px;
}
.quality-score-cont i {
  color: #dd872a !important;
}
.quality-score-cont i.active {
  opacity: 1;
  color: #dd872a !important;
}
.quality-score-cont i.no-active {
  opacity: 0.4;
  color: #dd872a !important;
}

.info-payament {
  list-style-type: none;
}
.info-payament li {
  max-width: 200px;
  margin: 10px auto;
  padding: 2%;
  text-align: center;
  color: #fff;
  border-radius: 4px;
}
.info-payament li i {
  color: #fff;
}

.pannelAdmin {
  padding: 3%;
  background-color: rgba(0, 0, 0, 0.03);
  cursor: pointer;
  text-align: center;
}
.pannelAdmin div {
  opacity: 0.8;
}
.pannelAdmin div:hover {
  opacity: 1;
}
.pannelAdmin i {
  color: #fff;
}

.warehouseStatus:disabled {
  opacity: 0.3 !important;
}

.watchlistInCollumn {
  margin: auto;
  text-align: center;
}
.watchlistInCollumn i {
  font-size: 30px;
  color: #c24e3f;
  cursor: pointer;
  opacity: 0.8;
  transition: 0.2s;
}
.watchlistInCollumn i:hover {
  font-size: 35px;
  color: #c24e3f;
  cursor: pointer;
  opacity: 1;
  transition: 0.2s;
}
.watchlistInCollumn .far {
  color: #bb9893 !important;
}

.splash-spinner circle {
  stroke: #264653 !important;
}

.cell-table div span {
  font-weight: bold;
}

.label-custom {
  font-weight: bold;
  color: #783233 !important;
}

.PannelProductDetail .form-group {
  background: rgba(0, 0, 0, 0.01);
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin: 1%;
  padding: 1%;
  border-radius: 4px;
}

.TableCustomProduct {
  text-align: center;
  background: #fff;
}
.btn-table {
  text-align: center;
  padding: 5px 7px 2px 7px;
  border-radius: 4px;
  opacity: 0.6;
  cursor: pointer;
}
.btn-table:hover {
  opacity: 1;
}
.btn-table i {
  color: #fff !important;
}

.pannelAdd {
  margin-left: 10px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 10px;
  background: #fff;
}
.pannelAdd .input-quantity {
  font-size: 20px;
  font-weight: 20px;
}
.ContentSelectProduct,
.ContentSelectPhoto {
  background: rgba(0, 0, 0, 0.02);
  padding: 2%;
  margin: 1% 0;
  border-radius: 10px;
}

.imgUpload {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.TableCustomlotPrev {
  text-align: center;
}
.imgCustomPrev {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;
}

.lotsTab {
  list-style: none;
  margin: 2% 0;
  padding: 0;
}
.lotsTab li {
  background: rgba(0, 0, 0, 0.04);
  margin: 0;
  padding: 7px 2% 7px 2%;
  border-radius: 4px;
}
.lotsTab h3 {
  font-size: 13px;
}
.lotsTab h5 {
  font-size: 12px;
}

.lotsTab .estimateLi {
  margin-top: 10px;
  background: rgba(214, 168, 120, 0.5);
  border-radius: 4px;
  font-size: 16px;
}

.estimateDiv {
  padding: 3%;
  background: rgba(214, 168, 120, 0.5);
  border-radius: 4px;
}
.estimateDiv .total_bid {
  padding: 3%;
  font-size: 20px;
  text-align: center;
}
.estimateDiv .description_bid {
  padding: 3%;
  color: #fff;
  background: rgba(214, 168, 120, 0.9);
  border-radius: 4px;
}
.smallInfo {
  background: rgba(0, 0, 0, 0.07);
  padding: 0 2% 0 2%;
  border-radius: 4px;
}

.lotDetailsAuction {
  margin-left: 1%;
  padding: 15px !important;
  cursor: pointer;
}
.titleProduct {
  font-size: 15px;
}

.buttonShippingView {
  border-radius: 4px;
  width: 50px;
  padding: 10px 0;
  font-size: 15px;
}
.buttonShippingView i {
  color: #fff;
}

.contButtonName {
  background: #292929;
  padding: 1px 6px;
  margin-left: 5px;
  border-radius: 4px;
}
.contButtonName i {
  color: #fff !important;
  font-size: 16px;
}

.widget1 .in-stat {
  position: relative !important;
}
.widget1 .in-stat-div {
  position: absolute;
  top: 0;
  padding-top: 5px;
  padding-left: 50px;
  font-size: 20px;
}

.widget2 .timeline:before {
  left: 85px !important;
}
.widget2 .timeline-label {
  min-width: 84px !important;
}

.widget6 .timeline:before {
  left: 150px !important;
}
.widget6 .timeline-label {
  min-width: 149px !important;
}

.widget7 .timeline:before {
  left: 150px !important;
}
.widget7 .timeline-label {
  min-width: 149px !important;
}

.avatar {
  vertical-align: middle;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.divider-address {
  background: rgba(63, 66, 84, 0.08);
  padding: 3%;
  margin: 2%;
  border-radius: 10px;
  box-sizing: border-box;
}
.divider-address ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.divider-address li {
  position: relative;
  padding: 3%;
  background: #fff;
  margin: 1% 0;
  border-radius: 10px;
  border-bottom: 3px solid #ddd;
}

.divider-address li .iconStar {
  position: absolute;
  left: -5px;
  top: 0;
  border-radius: 4px 0 10px 0;
}
.divider-address li .addressComplete {
  color: red;
}
.divider-address li .label {
  margin: 0 5px;
}

.priority-style {
  margin: auto;
  background: #dd872a;
  width: 50px;
  font-size: 15px;
  padding: 15px 0 10px 0;
  text-align: center;
  border-radius: 100%;
  border-bottom: 5px solid rgba(0, 0, 0, 0.2);
  color: #fff;
  font-weight: bold;
}
.priority-style-small {
  margin: auto;
  background: #dd872a;
  width: 30px;
  font-size: 15px;
  padding: 4px 0 2px 0;
  text-align: center;
  border-radius: 100%;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  color: #fff;
  font-weight: bold;
}

.MenuPriority {
  list-style-type: none;
}
.MenuPriority li {
  background: rgba(0, 0, 0, 0.02);
  padding: 5px;
  margin: 1% 0 1% 0;
  border-radius: 10px;
}
.MenuPriority .nameLi {
  padding: 10px 0 0 0;
}

.exTax-custom {
  margin: -5px 0 0 0;
  padding: 0;
  font-size: 13px;
}

.navbar-custom {
  margin: 1%;
  background: #fff !important;
  border-radius: 10px;
  font-size: 15px !important;
}
.navbar-custom .active {
  color: #0096c7 !important;
  font-weight: bold;
}
.navbar-custom .nav-link:after {
  margin-left: 10px;
  content: " |";
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: normal;
}

.contButtonOtherInfo {
  text-align: center;
  padding: 5px;
  border-radius: 10px;
  width: 50px;
  margin: auto;
}
.contButtonOtherInfo i {
  color: #fff;
  font-size: 30px;
}

.cont-plan {
  border: 1px solid #ddd;
  border-bottom: 3px solid #ddd;
  background: rgba(0, 0, 0, 0.02);
  margin: 1%;
  padding: 3%;
  text-align: center;
  border-radius: 10px;
}
.cont-plan .cont-avatar {
  max-width: 80%;
  margin: 10px auto;
}
.cont-plan .recurring-type {
  text-transform: capitalize;
}

.upgradePlanButtonAuction:hover {
  transition: 0.3s;
}
.upgradePlanButtonAuction:hover {
  font-size: 16px !important;
}

@media only screen and (max-width: 1000px) {
  .navbar-custom .nav-link:after {
    margin-left: 0;
    content: "";
  }
  .navbar-custom .nav-link {
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
  }
}

.customer-card {
  background-repeat: none;
  background-position: center;
  background-size: cover;
}

.card-profile {
  position: relative;
}
.card-profile .card {
  float: left;
  width: 100%;
  height: 70px;
}
.card-profile .card .role {
  margin: auto;
  color: rgba(224, 220, 203, 0.9);
  text-transform: uppercase;
}

.card-profile-topbar {
  position: relative;
}
.card-profile-topbar .card {
  float: left;
  width: 100px;
  height: 35px;
}
.card-profile-topbar .card .role {
  margin: auto;
  color: rgba(224, 220, 203, 0.9);
  text-transform: uppercase;
  font-size: 12px;
}
.card-profile-topbar .card .role a,
.card-profile-topbar .card .role a:hover {
  color: rgba(224, 220, 203, 0.9);
}

.PannelUpgrade {
  margin: 5% 0 5% 0;
}
.PannelUpgrade .card-auction {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: 0.3s;
  margin: auto;
  opacity: 0.9;
}
.PannelUpgrade .card-auction:hover {
  width: 45px;
  height: 45px;
  opacity: 1;
}
.PannelUpgrade .ListRoleAuction {
  list-style-type: none;
  padding-left: 10px;
  margin: auto;
  max-width: 150px;
}
.PannelUpgrade .ListRoleAuction li {
  float: left;
  height: 45px;
  margin: 1%;
}
.PannelUpgrade .ListRoleAuction li .disabled {
  opacity: 0.5;
}

.itemMenuCustomWidget {
  cursor: pointer;
  opacity: 0.5;
  transition: 0.03s;
  margin: 5px;
}
.itemMenuCustomWidget:hover {
  opacity: 1;
}
.symbol-custom {
  width: 100%;
}
.symbol-label-custom {
  width: 100%;
}

.alert {
  color: #fff !important;
}
.alert .message {
  padding: 5px;
}
.alert .button-close {
  margin-left: 10px;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: 0;
  color: #fff;
}
.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

.contStripe {
  min-width: 400px;
  width: 100%;
}
.contStripe input {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}
.contStripe .result-message {
  line-height: 22px;
  font-size: 16px;
}
.contStripe .result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}
.contStripe .hidden {
  display: none;
}
.contStripe #card-error {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
}
.contStripe #card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}
.contStripe #payment-request-button {
  margin-bottom: 32px;
}

/* Buttons and links */
.contStripe button {
  background: #00b4d8;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 0 0 4px 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
.contStripe button:hover {
  filter: contrast(115%);
}
.contStripe button:disabled {
  opacity: 0.5;
  cursor: default;
}

.divLoading {
  width: 100%;
  padding: 100px 0;
  text-align: center;
}
.spinner {
  padding-right: 10px;
}

.olCustom {
  padding: 0;
  margin: 0;
  padding: 10px 0;
}

body {
  margin: 0;
}

#container {
  width: 100%;
  overflow: auto;
}

#container > table {
  width: 200%;
  height: 100%;
  background: Lavender;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.clearfix {
  display: inline-block;
}
/* start commented backslash hack \*/
* html .clearfix {
  height: 1%;
}
.clearfix {
  display: block;
}
/* close commented backslash hack */

/* striped table checkbox */

.table-striped tbody tr:nth-of-type(2n + 1) .checkbox > span {
  background-color: #fff;
  border: 1px solid transparent;
}

.table-striped tbody tr:nth-of-type(2n + 1) .checkbox > input:checked ~ span {
  background-color: #3699ff;
}

thead {
  background-color: white;
  border-bottom: #264653 0.05rem solid;
}

.table.table-head-custom thead th {
  color: #3699ff !important;
}

.profile-header {
  background-color: #17a2b8;
}
.user-detail img {
  height: 100px;
  width: 100px;
}
.user-detail h5 {
  margin: 15px 0px 5px 0px;
}
.user-social-detail {
  padding: 15px 0px;
  background-color: #0077b6;
}
.user-social-detail a i {
  color: #fff;
  font-size: 23px;
  padding: 0px 5px;
}

#modal-scheda-utente {
  background-color: #caf0f8;
  border-bottom: 1px solid #264653 !important;
}
#modal-scheda-utente-footer {
  display: block !important;
  border-top: 1px solid #264653 !important;
}
.card.card-custom > .card-header {
  display: block !important;
}
.card.card-custom > .card-header .card-toolbar {
  display: block !important;
}

.card-spacer {
  background-color: white;
}

#icon-info-valutazione {
  font-size: 0.9rem !important;
  color: #25a244 !important;
}

#dash-right {
  float: right !important;
}

#clock {
  background-color: #03045e;
  color: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
}
.disabled-link {
  pointer-events: none;
}

.svg-icon.svg-icon-payment svg {
  height: 2rem !important;
  width: 48px !important;
}

.brand-dark .header-mobile {
  background-color: #264653 !important;
}

.brand-dark .header-mobile .btn .svg-icon svg g [fill] {
  transition: fill 0.3s ease;
  fill: #2a9d8f;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
  transition: fill 0.3s ease;
  fill: #2a9d8f;
}

.burger-icon span,
.burger-icon span:after,
.burger-icon span:before {
  background-color: #2a9d8f !important;
}

.topbar-mobile-on .topbar {
  box-shadow: 0px 0px 40px 0px rgba(198, 209, 209, 0.2);
  border-top: 1px solid #264653;
  background-color: #2a9d8f;
}

.checkbox {
  display: inline-block;
  vertical-align: top;
  padding-left: 25px;
  position: relative;
}

.checkbox input {
  position: absolute;
  left: 0;
  top: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.card-footer {
  padding: 2rem 2.25rem;
  background-color: #ebedf3;
  border-top: 1px solid #ebedf3;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  background-color: #ebedf3;
}

.text-primary {
  color: #03045e !important;
}

.card-header:first-child {
  border-radius: calc(0.42rem - 1px) calc(0.42rem - 1px) 0 0;
  background-color: whitesmoke;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.5rem 1.75rem;
  border-bottom: 3px solid #1e1e2d;
  border-top-left-radius: 0.42rem;
  border-top-right-radius: 0.42rem;
  background-color: #264653 !important;
}

.modal .modal-header .modal-title {
  font-weight: 500;
  font-size: 1.3rem;
  color: white !important;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 1.5rem;
  border-top: 1px solid grey;
  border-bottom-right-radius: 0.42rem;
  border-bottom-left-radius: 0.42rem;
}

.columnField {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

figure.snip0056 {
  font-family: "Raleway", Arial, sans-serif;
  position: relative;
  overflow: hidden;
  margin: 10px;
  min-width: 380px;
  max-width: 600px;
  width: 100%;
  background: #ffffff;
  color: #000000;
}
figure.snip0056 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
figure.snip0056 > img {
  width: 50%;
  border-radius: 50%;
  border: 4px solid #ffffff;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  -webkit-transform: scale(1.6);
  transform: scale(1.6);
  position: relative;
  float: right;
  right: -15%;
  z-index: 1;
}
figure.snip0056 figcaption {
  padding: 20px 30px 20px 20px;
  position: absolute;
  left: 0;
  width: 50%;
}
figure.snip0056 figcaption h2,
figure.snip0056 figcaption p {
  margin: 0;
  text-align: left;
  padding: 10px 0;
  width: 200px;
}
figure.snip0056 figcaption h2 {
  font-size: 1.3em;
  font-weight: 300;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
figure.snip0056 figcaption h2 span {
  font-weight: 800;
}
figure.snip0056 figcaption p {
  font-size: 0.9em;
  opacity: 0.8;
}
figure.snip0056 figcaption .icons {
  width: 100%;
  text-align: left;
}
figure.snip0056 figcaption .icons i {
  font-size: 26px;
  padding: 5px;
  top: 50%;
  color: #000000;
}
figure.snip0056 figcaption a {
  opacity: 0.3;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}
figure.snip0056 figcaption a:hover {
  opacity: 0.8;
}
figure.snip0056 > img {
  width: 30%;
  border-radius: 50%;
  border: 4px solid #ffffff;
  transition: all 0.35s ease-in-out;
  transform: scale(1.6);
  position: relative;
  float: right;
  right: -15%;
  z-index: 1;
  margin-right: 100px;
}
figure.snip0056.blue .position {
  background: #20638f;
}
figure.snip0056.red .position {
  background: #962d22;
}
figure.snip0056.yellow .position {
  background: #bf6516;
}
figure.snip0056:hover > img,
figure.snip0056.hover > img {
  right: -12%;
}

/* Demo purposes only */

form.index-search-form {
  position: relative;
  display: inline-flex;
}

button[name="submit"] {
  position: absolute;
  top: 0;
  right: 0;
}

input.search-box {
  width: 250px;
  padding: 2px 0;
}

.generatePassword:hover {
  background-color: #264653;
  color: white !important;
}

.dx-rg-bs4-table-header-title {
  color: black !important;
}

.card-header:first-child {
  border-radius: calc(0.42rem - 1px) calc(0.42rem - 1px) 0 0;
  background-color: white !important;
}

.table-bordered th,
.table-bordered td,
.oi {
  color: black;
}

.card-dashboard {
  min-height: 70px;
  padding-bottom: 0;
  background-color: transparent !important;
  display: block !important;
  border-radius: calc(0.42rem - 1px) calc(0.42rem - 1px) 0 0;
  padding-top: 1.25rem !important;
  border: 0 !important;
  padding: 2rem 2.25rem;
  margin-bottom: 0;
  box-sizing: border-box;
  word-wrap: break-word;
}

.oi-eye {
  color: black !important;
}

.oi-arrow-thick-top {
  color: black !important;
}

.oi-arrow-thick-bottom {
  color: black !important;
}

.table-active {
  background-color: white !important;
  border-bottom: #ebedf3 1px !important;
}

.table-striped tbody tr:nth-of-type(2n + 1) > .table-active {
  background-color: white !important;
}

.card-title-h3 {
  color: black;
  font-size: 0.9375rem;
  font-weight: 400;
}

.aside .aside-menu {
  background-color: #1e1e2d;
}

.aside .brand {
  background-color: #1e1e2d !important;
}

.aside {
  background-color: #1e1e2d;
  border-right: 1px solid #1e1e2d;
}
.card-dipendente {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.card-dipendente {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card-body-dipendente {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
}

.MuiAppBar-colorPrimary {
  color: rgba(0, 0, 0, 0.87);
  background-color: #264653 !important;
}

.MuiTab-wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: white !important;
}

.PrivateTabIndicator-colorSecondary-4 {
  background-color: whitesmoke !important;
}

.card-footer {
  padding: 2rem 2.25rem;
  background-color: #fff;
}
