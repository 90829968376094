#tabellaPresenze {
    display: block;
    padding: 10px;
    margin-bottom: 100px; /* SIMPLY SET THIS PROPERTY AS MUCH AS YOU WANT. This changes the space below box1 */
    text-align: justify;
}

#calendario {
    display: block;
    padding: 10px;
    text-align: justify;
    margin-top: 100px; /* OR ADD THIS LINE AND SET YOUR PROPER SPACE as the space above box2 */
}

#Task14 {
    display: block;
    padding: 10px;
    margin-bottom: 15px; /* SIMPLY SET THIS PROPERTY AS MUCH AS YOU WANT. This changes the space below box1 */
    text-align: justify;
}

#Task2 {
    display: block;
    padding: 1px;
    text-align: justify;
    margin-top: 15px; /* OR ADD THIS LINE AND SET YOUR PROPER SPACE as the space above box2 */
}
